import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "./plugins/vant.js";
import './assets/js/pxTurnRem.js'
// 在main.js中全局引入
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

Vue.config.productionTip = false

Vue.prototype.copy = function (str) {
  var _input = document.createElement("input"); // 直接构建input
  _input.style.fontSize = "12px";
  _input.value = str; // 设置内容
  document.body.appendChild(_input); // 添加临时实例
  _input.select(); // 选择实例内容
  document.execCommand("Copy"); // 执行复制
  document.body.removeChild(_input); // 删除临时实例
  this.$toast("复制成功");
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
