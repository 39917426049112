import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

// 创建对象，借助浏览器缓存，存入localStorage
const vuexLocal = new VuexPersist({
  storage: window.sessionStorage  // 可选存储方式
})

export default new Vuex.Store({
  state: {
    indexInfo: {
      thby:true,
      sxhmd: true,
      wlytk: true,
      pbtssj: true,
      llbbMin:1,
      llbbMax:4,
      lldpMin:1,
      lldpMax:4,
      hb:10,
      xl: 1000,
      jgqjMin:18,
      jgqjMax:30,
      xdfs:1,
      scxz:'1',
      skuxz:'2',
      sjxgmc:true,
    },
  },
  getters: {},
  mutations: {
    setIndexInfo(state, newVal) {
      Vue.set(state, 'indexInfo', newVal)
    },
  },
  actions: {},
  modules: {},
  // 引入 vuex-persist 插件
  plugins: [vuexLocal.plugin]
});
