export default [
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/index/news.vue')
  },
  {
    path: '/shareDetail',
    name: 'shareDetail',
    component: () => import(/* webpackChunkName: "shareDetail" */ '../views/share/shareDetail.vue')
  },
  {
    path: '/cash',
    name: 'cash',
    component: () => import(/* webpackChunkName: "cash" */ '../views/mine/cash.vue')
  },
  {
    path: '/real',
    name: 'real',
    component: () => import(/* webpackChunkName: "real" */ '../views/mine/real.vue')
  },
  {
    path: '/team',
    name: 'team',
    component: () => import(/* webpackChunkName: "team" */ '../views/mine/team.vue')
  },
  {
    path: '/cashDetail',
    name: 'cashDetail',
    component: () => import(/* webpackChunkName: "cashDetail" */ '../views/mine/cashDetail.vue')
  },
  {
    path: '/moneyDetail',
    name: 'moneyDetail',
    component: () => import(/* webpackChunkName: "moneyDetail" */ '../views/mine/moneyDetail.vue')
  },
  {
    path: '/buyDetail',
    name: 'buyDetail',
    component: () => import(/* webpackChunkName: "buyDetail" */ '../views/mine/buyDetail.vue')
  },
  {
    path: '/appxz',
    name: 'appxz',
    component: () => import(/* webpackChunkName: "appxz" */ '../views/mine/appxz.vue')
  },
  {
    path: '/newList',
    name: 'newList',
    component: () => import(/* webpackChunkName: "newList" */ '../views/index/newList.vue')
  },
  {
    path: '/bindBank',
    name: 'bindBank',
    component: () => import(/* webpackChunkName: "bindBank" */ '../views/mine/bindBank.vue')
  },
]